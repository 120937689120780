<script lang="ts" setup>
import EventList from '@/components/lists/EventList.vue'

const {company} = useCompany()
const title = computed<string>(() => company.value ? `Eventos | Venta de Entradas | ${company.value.name}` : '')

useHead({
  title: () => title.value
})

useSeoMeta({
  description: 'Descubre todos los eventos disponibles en tu ciudad. Conciertos, festivales, fiestas y más.',
  ogTitle: () => title.value,
  ogDescription: 'Descubre todos los eventos disponibles en tu ciudad. Conciertos, festivales, fiestas y más.',
  ogUrl: () => `/`,
  twitterTitle: () => title.value,
})
</script>

<template>
  <div>
    <div class="space-y-5 px-4 lg:px-20">
      <EventList title="Próximos eventos" status="open" />
      <EventList title="Eventos pasados" status="closed" />
    </div>
  </div>
</template>
