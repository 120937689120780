<script lang="ts" setup>
import {formatDate, getPagination, navigateTo} from '#imports'

const props = defineProps<{
  status: EventStatus,
  title: string
}>()

const pagination = ref<PaginationInput>({
  page: 1,
  per_page: 10
})

const fetchQuery = computed(() => ({
  type: 'public',
  status: props.status,
  ...pagination.value,
}))

const {data: objectList, status} = useApi<Paginated<EventInstance>>('/ticketing/events/', {
  query: fetchQuery,
  watch: [props]
})
</script>

<template>
  <div>
    <h3>{{ props.title }}</h3>
    <DataView
      :always-show-paginator="false"
      :loading="status === 'pending'"
      :rows="pagination.per_page"
      :total-records="objectList?.count"
      :value="objectList?.results"
      data-key="id"
      lazy
      paginator
      @page="(event) => {pagination = getPagination(event)}"
    >
      <template #empty>
        <div class="py-20 text-center text-surface-500">
          <ProgressSpinner v-if="status === 'pending'" />
          <span v-else>Vuelve pronto</span>
        </div>
      </template>

      <template #list="{ items }">
        <div class="gap-5 grid grid-cols-12">
          <Card
            v-for="object in items as EventInstance[]"
            :key="object.id"
            class="col-span-6 lg:col-span-4"
          >
            <template #header v-if="object.image_portrait">
              <img :alt="object.name" :src="object.image_portrait" />
            </template>

            <template #title>{{ object.name }}</template>

            <template #subtitle>{{ object.subtitle }}</template>

            <template #content>
              <div class="text-sm">
                <dl>
                  <dt class="font-semibold">Fecha</dt>
                  <dd>{{ formatDate(object.date, 'PPP') }} a las {{ formatDate(object.date, 'p') }}</dd>
                </dl>
                <dl>
                  <dt class="font-semibold">Lugar</dt>
                  <dd>{{ object.place }} - {{ object.location.full_name }}</dd>
                </dl>
              </div>
            </template>

            <template #footer>
              <div class="flex items-center justify-end">
                <Button
                  icon="pi pi-eye"
                  label="Ver"
                  @click="navigateTo({
                    name: 'events-slug',
                    params: { slug: object.slug }
                  })"
                />
              </div>
            </template>
          </Card>
        </div>
      </template>
    </DataView>
  </div>
</template>
